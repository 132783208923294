import React, { useEffect } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import LogoWhite from "../images/logo-white.png"
import favicon16 from "../images/favicon16.png"
import favicon32 from "../images/favicon32.png"
import favicon64 from "../images/favicon64.png"
import LunchBanner from "../images/lunch1.png"
import styled from "styled-components"

const Container = styled.main`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-image: url(${LunchBanner});
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  place-items: center;
`

const OverlayWrapper = styled.div`
  width: 80%;
  height: 80%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(122, 133, 157, 0.85);
`

const LogoImg = styled.img`
  width: 250px;
  align-self: center;
  margin-bottom: 50px;
  @media (max-height: 600px) and (min-width: 800px) {
    width: 150px;
  }
  @media (max-width: 500px) {
    width: 80%;
  }
`

const LocationLabel = styled.p`
  font-family: "OpenSans-Regular";
  font-size: 18px;
  color: white;
  margin: 0 0 10px 0;
  @media (max-height: 600px) and (min-width: 800px) {
    font-size: 16px;
  }
`

const LocationLink = styled(Link)`
  font-family: "GFSDidot-Regular";
  font-size: 48px;
  color: white;
  text-decoration: none;
  margin: 0 0 10px;
  font-weight: 300;
  letter-spacing: 2px;
  transition: opacity 0.25s ease;
  &:hover {
    opacity: 0.6;
  }
  @media (max-height: 600px) and (min-width: 800px) {
    font-size: 40px;
  }
  @media (max-width: 500px) {
    font-size: 30px;
  }
`

const IndexPage = () => {
  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)

    const handleResize = () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return (
    <>
      <Helmet
        link={[
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: `${favicon16}`,
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: `${favicon32}`,
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "64x64",
            href: `${favicon64}`,
          },
        ]}
      >
        <title>The MoMo House</title>
      </Helmet>
      <Container>
        <OverlayWrapper>
          <LogoImg src={LogoWhite} />
          <LocationLabel>Select a Location</LocationLabel>
          <LocationLink to="/yorkville">Yorkville</LocationLink>
          <LocationLabel>1240 Bay St. Unit 107</LocationLabel>
          <LocationLink to="/parkdale">Parkdale</LocationLink>
          <LocationLabel>1422 Queen St. W</LocationLabel>
          <LocationLink to="/queenAndBathurst">Queen & Bathurst</LocationLink>
          <LocationLabel>685 Queen St. W</LocationLabel>
        </OverlayWrapper>
      </Container>
    </>
  )
}

export default IndexPage
